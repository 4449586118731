import Vue from 'vue'
import Vuex from 'vuex'
import {baseImgUrl} from '@/utils/baseImgUrl'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseImgUrl: baseImgUrl
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
